import Input from 'components/globals/Input';
import useEnterSubmit from 'components/hooks/useEnterSubmit';
import useOnChange from 'components/hooks/useOnChange';
import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';

import auth from 'lib/auth';
import logger from 'lib/log';

import phone from 'phone';
import { FiLoader } from 'react-icons/fi';

import styles from './login.module.scss';
import { useRouter } from 'next/router';
import useWishlistRedirect from 'components/hooks/useWishlistRedirect';
import analytics from 'lib/analytics';
import DreamButton from 'components/globals/DreamButton';
import PhoneInput from 'components/landing/PhoneInput';
import firebase from 'lib/firebase';

interface LoginProps {
  children?: React.ReactNode;
}

enum LoginSteps {
  Phone = 'phone',
  Code = 'code',
  LinkLogin = 'linkLogin'
}

const Login: React.FC<LoginProps> = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneCountry, setPhoneCountry] = useState<string>('1');
  const [securityCode, setSecurityCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.Phone);
  const [loading, setLoading] = useState<boolean>(false);

  const [phoneInvalid, setPhoneNumberInvalid] = useState<boolean>();
  const [codeInvalid, setCodeInvalid] = useState<boolean>();

  const onCodeChange = useOnChange(setSecurityCode)
  const onPhoneChange = useOnChange(setPhoneNumber);

  const router = useRouter();

  useEffect(() => {
    auth.setupRecaptcha('recaptcha');

  }, []);


  // Redirect logged in users w/ a setup account
  useWishlistRedirect(router, {
    authCallback(user, fbUser) {
      if ((!user || !user.signupComplete) && fbUser) {
        router.push('/signup');
      }
    }
  });

  let buttonCopy;

  if (loading) {
    buttonCopy = <FiLoader className={styles.loader} />;
  } else if (loginStep === LoginSteps.Phone) {
    buttonCopy = 'Continue';
  } else {
    buttonCopy = 'Confirm Code';
  }

  const submitPhone = useCallback(async () => {
    if (loginStep !== LoginSteps.Phone) {
      return;
    }
    /**
     * Basic phone number validation
     */
    let normalizedPhone: string[] = [];

    try {
      normalizedPhone = phone(`+${phoneCountry}${phoneNumber.replace(/-/g, '')}`);
    } catch (err) {
      // do nothing
    }

    if (normalizedPhone.length === 0) {
      setError('Please enter a valid phone number.');
      setLoading(false);
      setPhoneNumberInvalid(true);
      return;
    }
    /**
     * Reset any error is it passes basic validation.
     */
    setPhoneNumberInvalid(false);
    setError('');
    setLoading(true);

    const parsedPhone = normalizedPhone[0];

    try {
      const goToSecurityCode = await auth.phoneNumberSignIn(parsedPhone);
      if (goToSecurityCode) {
        setLoading(false);
        setLoginStep(LoginSteps.Code);
      } else {
        setLoading(false);
        setError('Something went wrong. Plese try again.');
      }	
    } catch (e) {
      logger.error(e);
      setError('Something went wrong. Plese try again.');
      setLoading(false);
    }
  }, [setError, phoneNumber, phoneCountry, setLoading, setPhoneNumberInvalid, setLoginStep, loginStep]);

  const submitCode = useCallback(async () => {
    if (securityCode.length < 5) {
      setCodeInvalid(true);
      setError('Please enter a valid security code.');
      setLoading(false);
      return;
    }
    setCodeInvalid(false);
    setError('');
    setLoading(true);

    try {
      const signedInUser = await auth.confirmSecurityCode(securityCode);
      
      if (!signedInUser) {
        setError('The security code you entered was incorrect.');
        setLoading(false);
      } else {
        analytics.track('Login', { user_id: signedInUser.user?.uid });
      }
    } catch (error) {
      logger.error(error);
      if ((error as any)?.code === 'auth/user-disabled') {
        setError('Your user account has been deactivated, please contact us at support@dreamgifts.co if you believe this is an error');
      } else {
        setError('The security code you entered was incorrect.');
      }
      setLoading(false);
    }
  }, [setCodeInvalid, setError, setLoading, securityCode, router]);

  const onCodeKey = useEnterSubmit(submitCode);

  const onSubmit = loginStep === LoginSteps.Phone ? submitPhone : submitCode;

  useEffect(() => {
    if (router.query.token && typeof router.query.token === 'string') {
      setLoginStep(LoginSteps.LinkLogin);
      setLoading(true);
      handleLoginToken(router.query.token).catch((error) => {
        console.error('Error in handleLoginToken:', error);
        setError('An error occurred while processing the login link. Please try again.');
        setLoading(false);
        setLoginStep(LoginSteps.Phone);
      });
    }
  }, [router.query.token]);

  const handleLoginToken = async (token: string) => {
    try {
      const verifyLoginToken = firebase.functions().httpsCallable('verifyLoginToken');
      const result = await verifyLoginToken({ token });
      
      if (result.data && result.data.success) {
        try {
          await firebase.auth().signInWithCustomToken(result.data.customToken);
          const userDoc = await firebase.firestore().collection('users').doc(result.data.uid).get();
          const userData = userDoc.data();
          
          if (userData && userData.username) {
            router.push(`/wishlist/${userData.username}`);
          } else {
            router.push('/profile');
          }
        } catch (signInError) {
          console.error('Error signing in with custom token:', signInError);
          setError('Failed to sign in. Please try again.');
          setLoading(false);
          setLoginStep(LoginSteps.Phone);
        }
      } else {
        console.error('Invalid or expired token:', result.data);
        setError('Invalid or expired login link. Please request a new one.');
        setLoading(false);
        setLoginStep(LoginSteps.Phone);
        router.push('/');
      }
    } catch (error) {
      console.error('Error verifying login token:', error);
      setError('An error occurred. Please try again.');
      setLoading(false);
      setLoginStep(LoginSteps.Phone);
    }
  };

  return (<div className={styles.container}>
    <Head>
      <title>Dreamgifts - Login</title>
      {/* Twitter pixel */}
      <script dangerouslySetInnerHTML={{ __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('init','o8191');
twq('track','PageView');` }} />
    </Head>
    <div className={styles.contentWrap}>
      <div className={styles.content}>
        <img src="/dreamgifts-logo-ng.png" className={styles.logo} />
        {loginStep === LoginSteps.LinkLogin ? (
          <>
            <h3 className={styles.loginTitle}>Logging you in...</h3>
          </>
        ) : (
          <>
            <h3 className={styles.loginTitle}>Enter Your Number</h3>
            <PhoneInput
              value={phoneNumber}
              setValue={setPhoneNumber}
              country={phoneCountry}
              setCountry={setPhoneCountry}
              className={styles.phoneInput}
              onEnterPress={submitPhone}
              disabled={loginStep === LoginSteps.Code}
            />
            {loginStep === LoginSteps.Code && (
              <Input
                type="text"
                placeholder="Security code"
                value={securityCode}
                onChange={onCodeChange}
                onKeyDown={onCodeKey}
                invalid={codeInvalid}
                className="mt-4"
              />
            )}
            <DreamButton className={styles.loginButton} onClick={onSubmit}>{buttonCopy}</DreamButton>
            <div id="recaptcha"></div>
            <p className="text-red-600 my-2 text-center">{error}</p>
            {loginStep === LoginSteps.Phone && (
              <p className="text-gray-600 text-cente mt-5">By signing up, you agree to our <a className='underline' target="_blank" rel="noopener noreferrer" href="/terms">Terms of Service</a> and <a href="/privacy" className='underline' target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
            )}
          </>
        )}
      </div>
    </div>
    <div className={styles.coverImage}></div>
  </div>);
};

export default Login;