import { useCallback } from 'react';

export default function useOnChange(setter: (string: string) => void) {
  return useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setter(ev.target.value || ''); }, [setter]);
}

export function useItemDetailsOnChange(setter: (item: DG.ItemDetailsFormPayload) => void, field: keyof DG.ItemDetailsFormPayload, value: DG.ItemDetailsFormPayload) {
  return useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter({
      ...value,
      [field]: ev.target.value,
    });
  }, [setter, field,  value]);
}

