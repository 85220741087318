import React from 'react';
import cx from 'classnames';

interface CustomInputProps {
  invalid?: boolean;
  noBottomMargin?: boolean;
}

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & CustomInputProps;

interface FileInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  accept?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    invalid = false,
    noBottomMargin = false,
    className,
    disabled,
    ...inputProps
  } = props;

  const appliedClassName = cx(
    (!className || !className.includes('bg-')) && 'bg-gray-100',
    'rounded-lg p-4 w-full',
    !noBottomMargin && 'mb-3',
    invalid && 'border-2 border-red-400',
    disabled && 'opacity-50 cursor-not-allowed',
    className
  );

  return (<input
    {...inputProps}
    disabled={disabled}
    className={appliedClassName}
  />)
};

export const TextArea: React.FC<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & CustomInputProps> = (props) => {
  const {
    invalid = false,
    noBottomMargin = false,
    className,
    disabled,
    ...inputProps
  } = props;


  const appliedClassName = cx(
    (!className || !className.includes('bg-')) && 'bg-gray-100',
    'rounded-lg p-4 w-full',
    !noBottomMargin && 'mb-3',
    invalid && 'border-2 border-red-400',
    disabled && 'opacity-50 cursor-not-allowed',
    className
  );

  return <textarea {...inputProps} disabled={disabled} className={appliedClassName} ></textarea>;
}

export const FileInput: React.FC<FileInputProps> = ({ accept, onChange }) => (
  <input
    type="file"
    accept={accept}
    onChange={onChange}
    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
  />
);

export default Input;
