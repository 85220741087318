import React, { useCallback } from 'react';
import cx from 'classnames';
import { allCountries, Country } from 'country-telephone-data';
import Input from 'components/globals/Input';

import styles from './PhoneInput.module.scss';

interface PhoneInputProps {
  country: string;
  setCountry: (val: string) => void;
  value: string;
  setValue: (val: string) => void;
  className?: string;
  onEnterPress?: () => void;
  disabled?: boolean;
}

const uniqPhoneCode = (arr: Country[]) => {
  const seen: Record<string, boolean> = {};
  return arr.filter((el) => seen[el.dialCode] ? false : (seen[el.dialCode] = true))
};

const sortedCountries = uniqPhoneCode(allCountries);
sortedCountries.sort((a, b) => parseInt(a.dialCode, 10) < parseInt(b.dialCode, 10) ? -1 : 1);

const PhoneInput: React.FC<PhoneInputProps> = ({ country, setCountry, value, setValue, className, onEnterPress, disabled }) => {
  const handleCountrySelect = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(ev.target.value);
  }, [setCountry]);

  const handlePhoneKey = useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      if (onEnterPress) {
        onEnterPress();
      }
      return;
    }

    if (ev.key === 'Backspace') {
      if (value.charAt(value.length - 1) === '-') {
        setValue(value.slice(0, -2));
      } else {
        setValue(value.slice(0, -1));
      }

      return;
    }

    if (value.length >= 12) {
      return;
    }

    if (/[0-9]/.test(ev.key)) {
      if (country === '1' && (value.length === 2 || value.length === 6)) {
        setValue(value + ev.key + '-');
      } else {
        setValue(value + ev.key);
      }
    }

  }, [onEnterPress, value, setValue]);

  return (
    <div className={cx(className, 'relative')}>
      <select onChange={handleCountrySelect} value={country} className={styles.countryInput}>
        {
          sortedCountries.map(ctry => (<option key={ctry.dialCode} value={ctry.dialCode}>+{ctry.dialCode}</option>))
        }
      </select>
      <Input type="tel" value={value} noBottomMargin onKeyDown={handlePhoneKey} className={styles.textInput} disabled={disabled} />
    </div>
  )
};

export default PhoneInput;